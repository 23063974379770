/* Marquee 1---------------------------------- */
.marquee-area {
  padding: 25px 0;
}
.slider__marquee {
  overflow: hidden;
  margin: 0;
  margin-bottom: 0;

  .item {
    display: inline-flex;
    align-items: center;
    margin-right: 40px;

    font-family: $body-color;
    font-weight: 700;
    font-size: 42px;
    line-height: 55px;
    color: $title-color;
    display: inline-flex;
    align-items: center;

    img {
      margin-right: 37px;
    }

    span {
      display: inline-block;
      line-height: 1;

      &.text-stroke {
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px $title-color;
      }
    }
  }
}

/* Marquee 2---------------------------------- */
.slider__marquee.style2 {
  .item a {
    color: $white-color;
    span.text-stroke {
      -webkit-text-stroke: 1px $white-color;
    }
    img {
      filter: brightness(9) grayscale(9);
    }
  }
}
