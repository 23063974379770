.header-layout3 .header-top-bg-shape {
    left: 0;
    right: auto;
    clip-path: polygon(0 0%, calc(100% - 18px) 0%, 100% 100%, 0% 100%);
}
.header-layout3 .header-top-bg-shape:after {
    clip-path: polygon(0 0%, calc(100% - 18px) 0%, 100% 100%, 0% 100%);
    left: 0;
    right: auto;
}
.skill-feature.style2 .progress .progress-value {
    transform: translate(100%, 0);
}
.link-btn i {
    margin-right: 5px;
    margin-left: 0;
}
.team-card.style2 .team-card_wrapp .team-social .global-social {
    padding: 0;
}