/* Team global ---------------------------------- */
.team-area-1 {
  position: relative;
  margin: 0 90px;
  .icon-box {
    position: absolute;
    top: 212px;
    right: -20px;
    left: auto;
    justify-content: end;
    @include sm {
      display: none;
    }
  }

  @include xl {
    margin: 0 20px;
  }
}

.team-slider2 {
  .slick-dots {
    max-width: fit-content;
    height: 10px;
    background-color: $white-color;
    display: block;
    margin: auto;
    border-radius: 5px;
    margin-top: 40px;

    li {
      margin: 0;
    }

    button {
      width: 100px;
      height: 10px;
      // border-radius: 5px 0px 0px 5px;
      border-radius: 0;
      background-color: transparent;

      &:before {
        content: "";
        position: absolute;
        left: 0%;
        top: 0%;
        width: 100px;
        height: 10px;
        margin: 0;
        background-color: $theme-color;
        border-radius: 5px 0px 0px 5px;
        transition: all ease 0.4s;
        //margin-top: -6px;
        opacity: 0;
        visibility: hidden;
        display: none;
      }
    }

    .slick-active {
      button {
        background-color: $theme-color;
        border-radius: 5px;

        &::before {
          opacity: 1;
          visibility: visible;
          border-radius: 5px;
        }
      }
    }
  }
}

.team-card {
  position: relative;
  background: $white-color;
  border-radius: 20px;
  transition: 0.4s;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin-bottom: 40px;

  &_wrapp {
    position: relative;
    overflow: hidden;
  }

  .team-card_img {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px 20px 0 0;
      transition: 0.4s;
    }
  }

  &_img {
    position: relative;
    border-radius: 20px 20px 0 0;
    overflow: hidden;

    img {
    }
  }

  &_content {
    padding: 30px;

    @include lg {
      padding: 30px 20px;
    }
  }

  .plus-btn {
    position: absolute;
    right: 20px;
    bottom: 20px;
    height: 40px;
    background-color: $theme-color2;
    color: $white-color;
    width: 40px;
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    border-radius: 10px;
    transform: scaleY(1);
    cursor: pointer;
    z-index: 2;
    transition: all 0.3s;

    img {
      border-radius: 0;
    }
  }

  .global-social {
    position: absolute;
    bottom: -20px;
    right: 19px;
    display: block;
    text-align: center;
    border-radius: 10px;
    background-color: $theme-color;
    padding-left: 15px;
    opacity: 0;
    visibility: hidden;
    //transform: scaleY(0);
    transform-origin: bottom center;
    transition: all 500ms ease;
    z-index: 2;

    a {
      color: $white-color;
      font-size: 14px;
      line-height: 14px;
      border-radius: 0;
      display: inline-block;
      transition: all 0.4s ease-in-out;
      margin: 0 20px 0 10px;

      &:not(:last-child) {
        margin: 13px 11px 13px 4px;
        padding-right: 16px;
        border-right: 1px solid $white-color;
      }

      &:hover {
        background-color: transparent;
        color: $theme-color2;
      }

      img {
        border-radius: 0;
      }
    }
  }

  &_title {
    font-family: $title-font;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: $title-color;
    margin-bottom: 10px;
    margin-top: -0.3rem;

    a {
      color: $title-color;

      &:hover {
        color: $theme-color;
      }
    }
  }

  &_desig {
    font-family: $body-font;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    display: block;
    margin-bottom: -0.3rem;
  }

  &_text {
    p {
      margin-bottom: 0;
    }
  }

  &:hover {
    .plus-btn {
      transform: scaleY(0);
    }

    .global-social {
      bottom: 20px;
      opacity: 1;
      visibility: visible;
      //transform: scaleY(1);
    }

    .team-card_img {
      img {
        transform: scale(1.05);
      }
    }
  }
}

@include xs {
  .team-card .team-card_text {
    margin-top: -0.5em;
  }
}

/* team box  ----------------------------- */
.team-box {
  position: relative;
  border-radius: 20px;
  transition: all 0.7s;
  overflow: hidden;

  &_img {
    img {
      width: 100%;
      height: 100%;
    }
  }

  &_content {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -32px;
    padding: 40px;
    background: linear-gradient(
      3.65deg,
      #196164 5.86%,
      rgba(0, 77, 110, 0) 82.23%
    );
    border-radius: 20px;
    transition: all 0.4s;
  }

  &_title {
    font-size: 22px;
    font-weight: 700;
    color: $white-color;
    text-align: center;
    margin-top: -0.3rem;
    margin-bottom: 10px;
    &:hover {
      color: $theme-color;
    }

    a {
      color: inherit;
    }
  }

  &_desig {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    display: block;
    color: $white-color;
    margin-bottom: -0.3rem;
  }

  .team-social_wrap {
    text-align: center;
    margin-top: 0px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: all 0.7s;

    .social-btn {
      &.style2 {
        justify-content: center;

        a {
          background-color: $white-color;
          color: $theme-color;
          transition: all 0.7s ease;

          &:hover {
            background-color: $theme-color;
            color: $white-color;
          }
        }
      }
    }
  }

  &:hover {
    .team-box {
      &_content {
        bottom: 0;
      }
    }

    .team-social_wrap {
      margin-top: 18px;
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
}

/* Team Area 2 ---------------------------------- */
.team-card.style2 {
  display: block;
  border-radius: 20px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  padding: 10px;
  margin-bottom: 0;
  .team-card_img {
    width: 100%;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  .team-card_content {
    width: 100%;
    border-radius: 0px;
    position: relative;
    padding: 0;
  }

  .team-card_title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: start;
    a {
      color: $white-color;
      &:hover {
        color: $theme-color2;
      }
    }
  }

  .team-card_desig {
    display: block;
    text-align: start;
    font-size: 18px;
    font-weight: 400;
    color: $white-color;
  }
  .team-card_wrapp {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 20px;
    align-items: center;
    overflow: inherit;
    @include lg {
      padding: 30px 20px 20px;
    }
    .team-social {
      position: relative;
      .plus-btn {
        position: initial;
        width: 36px;
        height: 36px;
        line-height: 32px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.15);
        color: $white-color;
        filter: brightness(9) grayscale(9);
        transform: none;
      }
      .global-social {
        transform: none;
        bottom: 36px;
        padding-left: 0;
        display: inline-block;
        transform-origin: top;
        background: $theme-color2;
        border-radius: 30px;
        z-index: 1;
        right: 0;
        opacity: 0;
        visibility: hidden;
        a {
          border: 0;
          display: block;
          padding: 0;
          color: $title-color;
          margin: 20px 11px;
        }
      }
      &:hover {
        .plus-btn {
          background: $white-color;
          filter: none;
        }
        .global-social {
          opacity: 1;
          visibility: visible;
          bottom: 46px;
        }
      }
    }
  }
  &:hover {
    box-shadow: none;
  }
}
/* Team Area 3 ---------------------------------- */
.team-slider3 {
  .slick-dots {
    max-width: fit-content;
    height: 10px;
    background-color: #35797c;
    display: block;
    margin: auto;
    border-radius: 5px;
    margin-top: 80px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -70px;
    @include md {
      margin-top: 40px;
    }
    li {
      margin: 0;
      width: 100px;
    }

    button {
      width: 100px;
      height: 10px;
      // border-radius: 5px 0px 0px 5px;
      border-radius: 0;
      background-color: transparent;

      &:before {
        content: "";
        position: absolute;
        left: 0%;
        top: 0%;
        width: 100px;
        height: 10px;
        margin: 0;
        background-color: $theme-color;
        border-radius: 5px 0px 0px 5px;
        transition: all ease 0.4s;
        //margin-top: -6px;
        opacity: 0;
        visibility: hidden;
        display: none;
      }
    }

    .slick-active {
      button {
        background-color: $theme-color2;
        border-radius: 5px;

        &::before {
          opacity: 1;
          visibility: visible;
          border-radius: 5px;
        }
      }
    }
  }
}
/* Team Area 4 ---------------------------------- */
.team-card.style3 {
  display: flex;
  position: relative;
  overflow: hidden;
  background: #121212;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  .team-card-bg-shape {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    img {
      height: 100%;
      width: 300px;
    }
  }
  .team-card_content {
    padding: 40px;
    max-width: 340px;
  }
  .team-card_title {
    text-align: start;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2727;
    margin-bottom: 13px;
    a {
      color: $white-color;
      &:hover {
        color: $theme-color2;
      }
    }
  }
  .team-card_text {
    font-size: 18px;
    color: $white-color;
    line-height: 1.888;
    font-weight: 400;
    margin-bottom: -0.5em;
    display: block;
  }
  .team-card_img {
    flex: none;
    margin-right: 20px;
    align-self: flex-end;
  }
  @include ml {
    .team-card-bg-shape img {
      width: 250px;
    }
    .team-card_img {
      width: 200px;
      height: 234px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
  }
  @include lg {
    .team-card_content {
      max-width: 480px;
    }
  }
  @include sm {
    .team-card_content {
      padding: 30px;
    }
  }
  @include xs {
    display: block;
    .team-card-bg-shape {
      top: auto;
    }
    .team-card_img {
      width: auto;
      height: auto;
      margin: 0 15px;
      text-align: end;
      img {
        width: auto;
        height: auto;
      }
    }
  }
}

/* Team Area 5 ---------------------------------- */
.team-card.style4 {
  background: #121212;
  border-radius: 20px;
  padding: 30px;
  border: 1px solid transparent;
  margin-bottom: 0;
  .team-card_img {
    height: 310px;
    border-radius: 10px;
    transition: 0.4s;
    img {
      border-radius: 0;
      object-position: top;
    }
  }
  .team-card_content {
    padding: 30px 0 0;
    text-align: center;
  }
  .team-card_title {
    a {
      color: $white-color;
      &:hover {
        color: $theme-color2;
      }
    }
  }
  .team-card_wrapp {
    position: initial;
  }
  .global-social {
    bottom: 35px;
    right: auto;
    left: 50%;
    transform: translate(-50%, 0);
    top: auto;
    margin: 0;
    padding: 0;
    background: transparent;
    display: inline-flex;
    gap: 10px;
    a {
      width: 40px;
      height: 40px;
      line-height: 40px;
      border: 1px solid $theme-color2;
      border-radius: 50%;
      margin: 0;
      padding: 0;
      font-size: 16px;
      &:hover {
        background: $theme-color2;
        color: $title-color;
      }
    }
  }
  &:hover {
    border-color: $theme-color2;
    padding-bottom: 90px;
    .team-card_img {
      height: 250px;
    }
  }
}
/* Team Details ---------------------------------- */
.team-about-card {
  margin-bottom: 75px;

  .team-about-card_img {
    height: 100%;

    img {
      border-radius: 5px;
      height: 100%;
      object-fit: cover;
    }
  }

  .team-about-card_box {
    box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.1);
    padding: 40px;
    margin: 60px 0;
    border-radius: 5px;
    margin-left: -80px;
    background: $white-color;
  }

  &_title {
    margin-top: -0.3em;
    margin-bottom: 30px;
    font-weight: 700;
  }
}

.team-details-wrap {
  .team-details-wrap-title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 0;
  }

  .team-details-wrap-desig {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 12px;
  }
}

.team-details-wrap_info {
  display: flex;
  gap: 20px;

  &:not(:last-child) {
    margin-bottom: 25px;
  }

  .icon {
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 5px;
    background: $smoke-color;
    flex: none;
  }

  p {
    margin-top: -0.3em;
    margin-bottom: 0;

    a {
      color: $title-color;

      &:hover {
        color: $theme-color;
      }
    }
  }
}

@include md {
  .team-about-card .team-about-card_box {
    margin-left: 0;
  }

  .team-about-card {
    margin-bottom: 0;
  }
}
