/*----------------------------------------------
    # why choose us area style here
----------------------------------------------*/
.goal-tabs-wrapper {
    display: flex;
    justify-content: space-between;

    @include lg {
        display: block;
    }

    .nav-tabs.goal-tabs-tabs {
        max-width: 176px;
        gap: 20px;
        border-bottom: none;

        @include lg {
            max-width: 100%;
            margin-bottom: 70px;

        }

        @include md {
            gap: 8px;
        }

        .nav-link {
            background-color: $white-color;
            align-items: center;
            padding: 21px 30px;
            border: 1px solid rgba(25, 97, 100, 0.2);
            border-radius: 10px;
            min-width: 167px;
            font-size: 18px;
            font-weight: 400;
            color: var(--title-color);
            margin-bottom: 0;
            transition: all 0.4s ease-in-out;

            @include sm {
                min-width: 100%;
            }

            &:hover {
                background-color: $theme-color;
                color: $white-color;
            }

            &:hover,
            &.active,
            &:focus {
                border: 1px solid var(--theme-color);
                background-color: var(--theme-color);
                color: $white-color;
            }
        }
    }
}

.goal-list_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 65px 110px;

    @media(max-width: 1399px) {
        gap: 40px;
    }

    @include md {
        grid-template-columns: repeat(1, 1fr);
    }

}

.goal-content {
    &_wrapp {
        display: flex;
        align-items: flex-start;
        gap: 20px;
    }

    .box-title {
        font-size: 22px;
        font-weight: 700;
    }

    p {
        font-family: $body-font;
        font-weight: 500;
        font-size: 22px;
        line-height: 29px;
        color: rgba(25, 53, 45, 0.7);
    }
}

.goal-title-area {
    max-width: 400px;
    display: block;
    margin-left: auto;
    margin-bottom: 62px;

    @include md {
        max-width: 100%;
        margin-left: 0;
    }

    p {
        font-family: $body-font;
        font-weight: 400;
        font-size: 18px;
        line-height: 34px;
        color: $body-color;
    }
}


.wcu-area-1 {

    @include md {
        margin-bottom: 80px;
    }
}

.wcu-card-wrap.right-wrap {
    text-align: end;

    .wcu-card {
        flex-direction: row-reverse;
    }
}

.wcu-card {
    background-color: $white-color;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 0 30px 30px 30px;

    @media(max-width: 1399px) {
        padding: 0 20px 20px 20px;
    }

    @include lg {
        padding: 0 30px 30px 30px;
    }

    &-wrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;

        @media(max-width: 480px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .icon {
        height: 65px;
        width: 65px;
        line-height: 65px;
        flex: none;
        text-align: center;
        position: relative;
        margin-bottom: 25px;

        &:after {
            content: '';
            position: absolute;
            inset: 0;
            background: $theme-color;
            opacity: 0.1;
            border-radius: 0 0 10px 10px;
        }

        img {
            transition: 0.4s;
        }
    }

    .wcu-card-title {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 20px;
        margin-top: -0.2em;

        @media(max-width: 1399px) {
            font-size: 20px;
        }
    }

    .wcu-card-text {
        font-size: 18px;
        margin-bottom: -0.5em;
    }

    &:hover {
        .icon {
            img {
                transform: rotateY(180deg);
            }
        }
    }
}


.wcu-thumb {
    position: relative;

    .wcu-shape {
        position: absolute;
        left: 0;
        top: 20px;
        z-index: -1;
    }

    .wcu-shape2 {
        position: absolute;
        right: 30%;
        bottom: 7.6%;
    }
}

@include lg {
    .wcu-thumb-1 {
        text-align: center;
        margin-bottom: 50px;
    }

    .wcu-card-wrap.right-wrap {
        text-align: left;
    }

    .wcu-card-wrap.right-wrap .wcu-card {
        flex-direction: row;
    }
}



@media (max-width: 320px) {
    .wcu-card {
        flex-wrap: wrap;
    }
}

/* event area ***************************/
.event-area-1 {
    position: relative;
    padding: 135px 0;
    z-index: 2;
    overflow: hidden;

    @include md {
        padding: 80px 0 0 0;
    }
}

.event-shape {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;

    @include xxl {
        right: -10%;
    }

    @include lg {
        display: none;
    }
}

.event-card {
    display: flex;
    justify-content: flex-start;
    gap: 40px;
    margin-bottom: 65px;
    transition: all 0.4s ease-in-out;

    @include xl {
        margin-bottom: 30px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .event-card-title {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 7px;
    }

    .icon {
        min-width: 60px;
        transition: all 0.4s ease-in-out;
    }

    .event-card-text {
        font-size: 18px;
        margin-bottom: 0;
    }

    &:hover {
        .icon {
            transform: rotateY(180deg);
        }
    }
}

.event-thumb-1 {
    position: absolute;
    bottom: 0;

    @include md {
        position: relative;
    }
}

/*skill-feature 1***********************/
.skill-feature {
    &:not(:last-child) {
        margin-bottom: 25px;
    }

    .skill-feature_title {
        font-size: 24px;
        font-weight: 700;
        color: $title-color;
        margin-bottom: 6px;
        @include md {
            font-size: 20px
        }
    }

    .progress {
        background: #EFEFEF;
        height: 9px;
        position: relative;
        overflow: visible;

        .progress-bar {
            background: $theme-color;
            border-radius: 10px;
        }

        .progress-value {
            position: absolute;
            top: -35px;
            right: 0;
            font-size: 18px;
            font-weight: 400;
            font-family: $title-font;
            color: $title-color;
        }
    }
}
/*skill-feature 2***********************/
.skill-feature.style2 {
    &:not(:last-child) {
        margin-bottom: 30px;
    }
    .skill-feature_title {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 24px;
    }
    .progress {
        height: 3px;
        background: #F3F5F4;
        .progress-value {
            font-size: 18px;
            position: relative;
            transform: translate(-100%, 0);
        }
    }
}
/************** wcu-thumb-wrap ***************/
.wcu-thumb-wrap {
    position: relative;
    display: flex;
    justify-content: end;

    img {
        border-radius: 10px;
    }

    .img-1 {
        height: fit-content;
    }

    .img-2 {
        margin: 120px 0 0 30px;
    }

    @include lg {
        justify-content: start;
    }

    @include sm {
        display: block;

        img {
            width: -webkit-fill-available;
            max-height: 300px;
            object-fit: cover;
        }

        .img-1 {
            margin-right: 60px;
        }

        .img-2 {
            margin: 30px 0 0 60px;
        }
    }
}