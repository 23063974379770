@each $bgcolorMap, $value in $bgcolorMap {
  .bg-#{$bgcolorMap} {
    background-color: #{$value} !important;
  }
}

.background-image,
[data-bg-src] {
  @include background-content(no-repeat, cover, center center);
}

.bg-fluid {
  @include background-content(no-repeat, 100% 100%, center center);
}

.bg-auto {
  background-size: auto auto;
}

.bg-cta {
  background-color: #D7D5FF;
}

.bg-top-center {
  background-size: auto;
  background-position: top center;
}

.bg-repeat {
  background-size: auto;
  background-repeat: repeat;
}
