/* Video 1 ---------------------------------- */ 
.video-area-1 {
    .video-wrap {
        display: block;
        text-align: center;
        position: relative;
        img {
            border-radius: 20px;
            width: 100%;
            height: 480px;
            object-fit: cover;
        }
        .play-btn {
            border-radius: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
        }
    }    
}
