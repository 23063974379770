.hero-2 {
    direction: ltr;
}

.hero-style2 {
    direction: rtl;
}
.hero-client-box {
    direction: rtl;
}
.service-title-area {
    margin-right: auto;
    margin-left: 0;
}
.testimonial-area-2.arrow-wrap .slick-arrow {
    right: auto;
}
.testimonial-area-2.arrow-wrap .slick-arrow.slick-next {
    right: 28%;
}
.event-shape {
    left: 0;
    right: auto;
}
.blog-box .blog-meta a img {
    margin-left: 9px;
    margin-right: 0;
}
